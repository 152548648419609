export default class PageOptions {
    constructor() {
        this.FooterClass = null;
        this.CurrentPage = 0;
        this.TxtNext = "Próximo";
        this.TxtPreview = "Voltar";
    }

    // Define qual classe será aplicada ao footer
    changeClass(newClass) {
        this.FooterClass = newClass;
    }
}