<template>
  <div class="breadcrumb">
    <div v-for="(level, index) in levels" :key="level.pathName">
      <a
        href="javascript:;"
        @click.prevent="changeLocation(level.pathName)"
        style="cursor: default"
      >{{level.text}}</a>
      <span v-if="index != levels.length - 1">/</span>
    </div>
  </div>
</template>

<script type="plain/text">
export default {
  props: ["levels"],
  methods: {
    changeLocation(pathName) {
      if (pathName) {
        if (pathName == -1) this.$router.go(-1);
        this.$router.push({ name: pathName });
      }
    }
  }
};
</script>
